<template>
  <div class="xnib-main">
    <div class="xnib-wrapper" v-if="showNibbleUI" :style="wrapperStyle">
      <div :class="containerClass" :style="containerStyle">
        <start-button ref="button"
          :white-label="whiteLabel"
          :button-action="buttonAction"
          :widget-content="widgetContent"
          :theme="loadedTheme.button"
          @click="mainButtonClick"/>
        <info-text
          v-if="showInfoText"
          :button-action="buttonAction"
          :widget-content="widgetContent"
          :theme="{infoText: loadedTheme.infoText, infoPopup: loadedTheme.infoPopup}"
          @click="mainButtonClick"/>
      </div>
      <info-box
        v-if="displaySetting === 'infobox'"
        :button-action="buttonAction"
        :widget-content="widgetContent"
        :theme="loadedTheme"
        @primaryButtonClick="buttonActionClick(widgetContent.primary_button_action)"
        @secondaryButtonClick="buttonActionClick(widgetContent.secondary_button_action)"/>
      <exit-popup
        v-if="displaySetting === 'modal'"
        :white-label="whiteLabel"
        :widget-content="widgetContent"
        :theme="loadedTheme"
        @exit="exitIntentPopupExited"
        @buttonClick="mainButtonClick"
        @primaryButtonClick="buttonActionClick(widgetContent.primary_button_action)"
        @secondaryButtonClick="buttonActionClick(widgetContent.secondary_button_action)"/>
      <dwell-popup
        v-if="displaySetting === 'popin'"
        :white-label="whiteLabel"
        :widget-content="widgetContent"
        :theme="loadedTheme"
        @exit="dwellTimePopupExited"
        @buttonClick="mainButtonClick"
        @primaryButtonClick="buttonActionClick(widgetContent.primary_button_action)"
        @secondaryButtonClick="buttonActionClick(widgetContent.secondary_button_action)"/>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce'
import metaParser from 'metaviewport-parser'
import NibbleStorage from '@/utils/NibbleStorage'
import PromiseQueue from '@/utils/PromiseQueue'
import MultiCurrencyAppAdapter from '@/adapters/MultiCurrencyAppAdapter'
import ReChargeSubscriptionsAdapter from '@/adapters/ReChargeSubscriptionsAdapter'
import GlobalEAdapter from '@/adapters/GlobalEAdapter'
import StartButton from '@/components/StartButton'
import InfoText from '@/components/InfoText'
import InfoBox from '@/components/InfoBox'
import ExitPopup from '@/components/ExitPopup'
import DwellPopup from '@/components/DwellPopup'
import { defaultTheme, mergeThemes } from '@/utils/theme'
import createTrigger from '@/utils/triggers'

export default {
  name: 'NibbleButton',
  components: { StartButton, InfoText, InfoBox, ExitPopup, DwellPopup },
  data () {
    return {
      fonts: [
        'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap',
        'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;800&display=swap'
      ],
      viewId: null,
      positionDOM: null,
      nibbleButtomElement: null,
      triggered: false,
      hiddenWhileConfiguring: true,
      activeTrigger: null,
      displayStyle: null,
      showMainButton: false,
      autoActivate: this.autoOpen || NibbleStorage.checkAutoActivateCookie(),
      buttonAction: null,
      widgetContent: {},
      nibbleWindowRoot: null,
      features: [],
      theme: null,
      scriptUrl: null,
      previousViewportSetting: null,
      sessionCreationToken: null,
      chatVisible: false,
      t: NibbleStorage.getT(),
      visitNumber: null,
      campaignKey: null,
      pageToken: NibbleStorage.getPageToken(), // used to match will other nibble buttons on the same page
      pageViewImprintId: null, // used to match against reconfigurations of the same button
      tokenKey: null,
      widgetTokenKey: null,
      optoutKey: NibbleStorage.getCookie('nibble-optout') || null,
      cartSummary: null,
      cartTotal: this.cartValue,
      retailerSessionId: null,
      activated: false, // have we been shown / configured at least once?
      configurePromiseQueue: new PromiseQueue(),
      configurations: {}, // cache of nibble config by productId => productVariantId => config data
      parsedJson: {}
    }
  },
  props: {
    windowScriptUrl: {
      // Add this to override the default loading url of the Nibble Window script
      type: String,
      require: false,
      default: null
    },
    show: {
      // Extra toggle, hides the Nibble button if set to false. If true, button showing
      // is determined by normal configuration
      type: String,
      default: 'true'
    },
    apiKey: {
      type: String,
      required: true
    },
    pageType: {
      // product, cart, other
      type: String,
      required: false,
      default: 'product'
    },
    negotiationType: {
      // product, cart, product_buyback
      type: String,
      required: false,
      default: 'product'
    },
    currencyCode: {
      // ISO 4217 currency code. Required for cart negotiations
      type: String,
      required: false,
      default: null
    },
    cartValue: {
      // Total value of the cart in specified currency. Required for cart negotiations
      type: String,
      required: false
    },
    productId: {
      // Product ID. Required for product negotiations
      type: String,
      required: true
    },
    subProductId: {
      // Sub product / variant / colourway ID. Valid for product negotiations
      type: String,
      required: false
    },
    variantId: {
      // Sub product / variant / colourway ID. Valid for product negotiations. Same as subProductId
      // but with a different name for future-proofing
      type: String,
      required: false
    },
    productTags: {
      // Category / cohort tags. Valid for product negotiations
      type: String,
      required: false
    },
    apiVersion: {
      // dev, preprod, prod
      type: String,
      default: 'prod'
    },
    windowPosition: {
      // Options:
      // right: center right of browser window
      // button: centered on Nibble Button
      // center: center of browser window
      // container: fills the containing element
      type: String,
      default: 'right'
    },
    headerHeight: {
      // How much space to reserve at the top of the browser window
      type: Number,
      default: 100
    },
    skipFonts: {
      // Don't load font CSS (if you already have it loaded)
      type: String,
      required: false,
      default: 'false'
    },
    featureNames: {
      // Override widget features. Will override session features if API key has debug access
      type: String,
      required: false,
      default: ''
    },
    dataJson: {
      // Data that will be stored against the session, can be used for tracking purposes
      type: String,
      required: false,
      default: '{}'
    },
    cartNegotiationUrl: {
      // When instigating a cart negotiation, if this URL is present, redirect there instead
      type: String,
      required: false
    },
    autoOpen: {
      // Force the nibble widget to open automatically (if after configuration it is able to)
      type: Boolean,
      required: false,
      default: false
    },
    isSecondaryWidget: {
      // Indicates the button is not the main one on the page (e.g. it's in a cart drawer)
      // Prevents the widget from auto opening
      type: Boolean,
      required: false,
      default: false
    },
    deferConfiguration: {
      // If true, the button will not attempt to load configuration until it is
      // fully configured with a cart summary (and with a product for product negotiations)
      type: Boolean,
      required: false,
      default: false
    },
    language: {
      type: String,
      required: false,
      default: 'en-GB'
    }
  },
  created () {
    // Debounced version of loadConfiguration() must be created when the
    // component is created, otherwise it will be reused between all components
    this.debouncedLoadConfiguration = debounce(this.loadConfiguration, 50)
  },
  mounted () {
    window.addEventListener('nibble-cart-update', this.updateCart)
    this.findUrlKeys()
    this.validate()
    if (this.windowScriptUrl != null) {
      this.scriptUrl = this.windowScriptUrl
    } else {
      this.scriptUrl = process.env.VUE_APP_WIDGET_BASE_URL + '/nibble-window.min.js'
    }
    this.resolveFonts()
    // The shopify app may set show=false if it doesn't yet know what variant
    // (sub-product) we are looking at.
    this.debug(`Mounted. show: ${this.show}`)
    this.$emit('nibble-loaded')
    this.loadConfigurationIfReady()
  },
  beforeUnmount () {
    window.removeEventListener('nibble-cart-update', this.updateCart)
    if (this.adapter != null) {
      this.adapter.deactivate()
    }
  },
  watch: {
    show () {
      this.debug(`Show updated. Show:${this.show}`)
      this.loadConfigurationIfReady()
    },
    subProductId () {
      this.debug(`Sub Product updated. Show:${this.show}`)
      this.loadConfigurationIfReady()
    },
    cartValue () {
      this.debug(`Cart Value updated. Show:${this.cartValue}`)
      this.cartTotal = this.cartValue
      this.loadConfigurationIfReady()
    },
    featureNames () {
      this.debug(`Feature Names updated. Show:${this.featureNames}`)
      this.configurations = []
      this.loadConfigurationIfReady()
    },
    language () {
      this.debug(`Language updated. Show:${this.language}`)
      this.configurations = []
      this.loadConfigurationIfReady()
    }
  },
  computed: {
    apiUrl () {
      return process.env.VUE_APP_API_BASE_URL
    },
    showNibbleUI () {
      /* Prechecks for deciding to display the button */
      return (
        // Are we not explicitly disabled by the 'show' attribute?
        this.show !== 'false' && this.show !== 'f' &&
        // Are we explicitly enabled by server-side configuration?
        this.triggered &&
        // Final check - if we have an adapter, does it allow us to proceed?
        (this.adapter == null || this.adapter.state.showNibble)
      )
    },
    nibbleWindow () {
      return this.nibbleWindowRoot.shadowRoot.querySelector('.xnib-backdrop')
    },
    tags () {
      var tags = []
      if (this.productTags != null) {
        tags = this.productTags.split(',')
      }
      return tags
    },
    containerClass () {
      var classes = ['xnib-container']
      if (this.showNibbleUI && this.showMainButton) {
        classes.push('show')
      }
      return classes
    },
    containerStyle () {
      var namespace = this.loadedTheme.container
      var prefix = '--xnib-container-'
      return {
        [`${prefix}max-width`]: namespace.maxWidth,
        [`${prefix}min-width`]: namespace.minWidth,
        [`${prefix}margin`]: namespace.margin,
        [`${prefix}padding`]: namespace.padding,
        [`${prefix}float`]: namespace.float
      }
    },
    wrapperStyle () {
      if (this.hiddenWhileConfiguring) {
        return {
          display: 'none'
        }
      } else {
        return {
          display: 'block'
        }
      }
    },
    loadedTheme () {
      // Use styles from loaded theme (using defaults for any unspecified settings) if given, otherwise use default theme
      return this.theme ? mergeThemes(defaultTheme, this.theme) : defaultTheme
    },
    whiteLabel () {
      return this.features != null && this.features.indexOf('white_label') !== -1
    },
    configurationKey () {
      if (this.productId != null) {
        if (this.productVariantId != null) {
          return `product-${this.productId}-${this.productVariantId}`
        } else {
          return `product-${this.productId}`
        }
      } else if (this.cartSummary != null) {
        return `cart-${this.cartSummary.configurationKey}`
      } else if (this.cartTotal != null) {
        return `cart-${this.currencyCode}-${this.cartTotal}`
      } else {
        return null
      }
    },
    showInfoText () {
      return (this.loadedTheme.infoText.enabled === 'true' && this.buttonAction === 'negotiate') ||
        (this.widgetContent != null && this.widgetContent.error_message != null)
    },
    productVariantId () {
      return this.variantId || this.subProductId
    }
  },
  methods: {
    // Saves the position of the nibble-button element
    saveDOMPosition () {
      var element = this.$el.getRootNode().host
      this.positionDOM = {
        element: element,
        parent: element.parentNode,
        prevSibling: element.previousSibling
      }
    },
    exitIntentPopupShown () {
      // If we're not showing the button, keep it in its original position.
      // Otherwise, move it to the bottom of the DOM.
      if (this.features.indexOf('exit_intent_popup_hide_button') === -1 && this.features.indexOf('dont_move_button_for_popups') === -1) {
        this.moveToBottomOfDOM()
      }
    },
    dwellPopupShown () {
      // If we're not showing the button, keep it in its original position
      // Otherwise, move it to the bottom of the DOM.
      if (this.features.indexOf('dwell_time_popup_hide_button') === -1 && this.features.indexOf('dont_move_button_for_popups') === -1) {
        this.moveToBottomOfDOM()
      }
    },
    moveToBottomOfDOM () {
      // Move the nibble-button element to the end of the document body in the DOM tree.
      // This will usually ensure the popup is displayed on top of any other elements that
      // have the same z-index.
      this.saveDOMPosition()
      document.body.appendChild(this.positionDOM.element)
    },
    /** Moves the nibble-button element back to its original position:
     * - Place it next to the sibling if there was a sibling before or after
     * - Append it as a child to the parent element if there were no siblings */
    restoreDOMPosition () {
      var pos = this.positionDOM
      if (pos != null) {
        if (pos.prevSibling == null) {
          pos.parent.appendChild(pos.element)
        } else {
          pos.parent.insertBefore(pos.element, pos.prevSibling.nextSibling)
        }
      }
    },
    /** Actions to perform after any interactions on the dwell time or exit intent popups
     * 1. Move the nibble-button element back to its original position in the DOM
     * 2. Display the main Nibble button */
    exitIntentPopupExited () {
      this.restoreDOMPosition()
      if (this.features.indexOf('exit_intent_popup_hide_button') === -1) {
        this.showMainButtonIfPossible()
      }
    },
    dwellTimePopupExited () {
      this.restoreDOMPosition()
      if (this.features.indexOf('dwell_time_popup_hide_button') === -1) {
        this.showMainButtonIfPossible()
      }
    },
    showMainButtonIfPossible () {
      if (this.buttonAction === 'negotiate' || (this.buttonAction === 'checkout' && this.displaySetting === 'always')) {
        this.showMainButton = true
      }
    },
    debug (arg) {
      if (this.apiVersion !== 'prod') {
        // eslint-disable-next-line
        console.log(arg)
      }
    },
    loadNibbleWindowScript () {
      if (window.customElements.get('nibble-window') != null) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return this.loadScript(this.scriptUrl)
      }
    },
    loadScript (src) {
      return new Promise(function (resolve, reject) {
        let shouldAppend = false
        let el = document.querySelector('script[src="' + src + '"]')
        if (!el) {
          el = document.createElement('script')
          el.type = 'text/javascript'
          el.async = true
          el.src = src
          shouldAppend = true
        } else if (el.hasAttribute('data-loaded')) {
          resolve(el)
          return
        }

        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)
        el.addEventListener('load', function loadScriptHandler () {
          el.setAttribute('data-loaded', true)
          resolve(el)
        })

        if (shouldAppend) document.head.appendChild(el)
      })
    },
    unloadScript  (src) {
      return new Promise(function (resolve, reject) {
        const el = document.querySelector('script[src="' + src + '"]')

        if (!el) {
          reject(new Error('No such script to unload'))
          return
        }

        document.head.removeChild(el)

        resolve()
      })
    },
    findUrlKeys () {
      const urlParams = new URLSearchParams(window.location.search)
      // Campaign key: used for 'Magic Links' that enable rules
      this.campaignKey = urlParams.get('xnibci')
      // Token key: used for Recovered Nibbles
      this.tokenKey = urlParams.get('xnibtok')
      // Widget token key: used for passing state during multi-page flows
      // (e.g. AOV signposting)
      this.widgetTokenKey = urlParams.get('xnibwt')
      // Opt-out key: used to override Nibble's default behaviour
      // (e.g. opt out of A/B tests or disable Nibble)
      const optoutKey = urlParams.get('xniboptout')
      if (optoutKey != null) {
        if (this.optoutKey !== optoutKey) {
          if (optoutKey === 'tests') {
            alert('You have successfully opted out of Nibble A/B tests. Clear cookies to reset optouts.')
          } else if (optoutKey === 'all') {
            alert('You have successfully opted out of Nibble. Clear cookies to reset optouts.')
          }
          this.optoutKey = optoutKey
          // Optout is saved for a year
          NibbleStorage.setCookie('nibble-optout', this.optoutKey, 365 * 24 * 60 * 60)
        }
      }
    },
    validate () {
      if (this.apiKey == null) {
        throw Error('Nibble: apiKey must be specified')
      }
      if (['cart', 'product'].indexOf(this.negotiationType) === -1) {
        throw Error('Nibble: negotiationType must be product or cart')
      }
      if (this.negotiationType === 'product' && this.productId == null) {
        throw Error('Nibble: productId must be specified if negotiationType is product')
      }
      if (['right', 'center', 'button', 'container'].indexOf(this.windowPosition) === -1) {
        throw Error('Nibble: window position must be right, button, center or container')
      }
      if (typeof this.headerHeight !== 'number' || this.headerHeight < 0) {
        throw Error('Nibble: header height must be a non-negative number')
      }
      if (this.skipFonts != null && ['true', 'false'].indexOf(this.skipFonts) === -1) {
        throw Error('Nibble: skipFonts, if specified, must be true or false')
      }
    },
    resolveFonts () {
      if (this.skipFonts != null && this.skipFonts === 'true') {
        return
      }
      // We can't reliably check whether a font is already loaded due to privacy
      // restrictions. So load all our fonts anyway.
      this.fonts.forEach((font) => {
        var link = document.createElement('link')
        link.href = font
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.media = 'screen,print'
        var container = document.querySelector('head')
        if (container == null) {
          container = document.body
        }
        container.appendChild(link)
      })
    },
    updateCart (event) {
      this.debug(`Cart updated. Show:${this.show}`)
      this.cartSummary = event.detail
      if (this.cartSummary != null) {
        this.retailerSessionId = this.cartSummary.retailerSessionId || this.cartSummary.sessionToken
        this.cartSummary.configurationKey = NibbleStorage.generateRandomString(10)
        this.cartTotal = this.cartSummary.originalTotalPrice
      }
      this.loadConfigurationIfReady()
    },
    loadConfigurationIfReady () {
      const hasNegotiationData = (this.negotiationType === 'product' && this.productId != null) ||
        (this.negotiationType === 'cart' && this.cartSummary != null) ||
        (this.negotiationType === 'cart' && this.currencyCode != null && this.cartValue != null)
      const hasCartData = this.cartSummary != null
      if (this.show !== 'false' && this.show !== 'f' && hasNegotiationData && (!this.deferConfiguration || hasCartData)) {
        this.hiddenWhileConfiguring = true
        this.debouncedLoadConfiguration()
      }
    },
    loadConfiguration () {
      this.debug('Load configuration')
      const firstTime = !this.activated
      this.activated = true
      var configKey = this.configurationKey
      var config = this.configurations[configKey]
      if (config != null) {
        this.setConfiguration(configKey, config, firstTime)
      } else {
        this.hiddenWhileConfiguring = true
        this.configure(configKey, firstTime)
      }
    },
    configure (configKey, firstTime) {
      this.debug('Configure')
      if (this.activeTrigger != null) {
        this.activeTrigger.stop()
        this.activeTrigger = null
      }
      this.configurePromiseQueue.enqueue(() => {
        const data = {
          nibbleApiKey: this.apiKey,
          retailerSessionId: this.retailerSessionId,
          pageToken: this.pageToken,
          pageType: (this.pageType || this.negotiationType),
          negotiationType: this.negotiationType,
          cart: this.cartSummary,
          originalPrice: this.cartTotal,
          currencyCode: this.currencyCode,
          productId: this.productId,
          subProductId: this.productVariantId,
          productViewId: this.pageViewImprintId,
          visitNumber: this.visitNumber,
          tags: this.tags,
          t: this.t,
          campaignKey: this.campaignKey,
          tokenKey: this.tokenKey,
          widgetTokenKey: this.widgetTokenKey,
          optoutKey: this.optoutKey,
          pageUrl: this.getEncodedPageUrl(),
          features: this.featureNames.split(','),
          language: this.language
        }
        return fetch(`${this.apiUrl}/configure`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          redirect: 'follow',
          headers: {
            'X-Api-Key': this.apiKey,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(response => response.json())
          .then((responseData) => {
            return this.setConfiguration(configKey, responseData, firstTime)
          })
      })
    },
    setConfiguration (configKey, data, firstTime) {
      console.log(`setConfiguration. configKey:${configKey}; data:${data}; firstTime:${firstTime}`)
      this.configurations[configKey] = data
      this.pageViewImprintId = data.productViewId
      this.features = data.features
      this.visitNumber = data.visitNumber
      this.loadAdapter()
      this.hiddenWhileConfiguring = false
      this.theme = data.theme
      this.buttonAction = null
      this.widgetContent = {}
      const showWindow = data.open_window && !this.triggered && !this.isSecondaryWidget
      if (this.triggered) {
        this.buttonAction = data.button_action || 'negotiate'
        this.widgetContent = data.widget_content
        this.showMainButton = (this.displaySetting === data.display_type && this.showMainButton) || data.display_type === 'button'
        this.displaySetting = data.display_type
      } else {
        this.activeTrigger = createTrigger(data.display_trigger, data.delay, () => {
          this.triggered = true
          this.buttonAction = data.button_action || 'negotiate'
          this.widgetContent = data.widget_content
          this.showMainButton = (this.displaySetting === data.display_type && this.showMainButton) || data.display_type === 'button'
          this.displaySetting = data.display_type
        })
        if (this.activeTrigger != null) {
          this.activeTrigger.start()
        }
      }
      this.$emit('nibble-configured', {
        buttonAction: this.buttonAction,
        displaySetting: this.displaySetting
      })
      console.log(`setConfiguration. triggered:${this.triggered}; displaySetting:${this.displaySetting}; showMainButton:${this.showMainButton}`)
      if (firstTime) {
        this.loadNibbleWindowScript()
          .then(() => {
            const nibbleWindow = document.createElement('nibble-window')
            nibbleWindow.setAttribute('api-url', this.apiUrl)
            nibbleWindow.setAttribute('api-key', this.apiKey)
            nibbleWindow.setAttribute('position', this.windowPosition)
            nibbleWindow.setAttribute('header-height', this.headerHeight)
            nibbleWindow.addEventListener('dismissed', this.chatDismissed)
            nibbleWindow.addEventListener('nibble-session-end', this.endSession)
            nibbleWindow.addEventListener('nibble-add-product-to-basket', this.addEventProductToBasket)
            // Workaround for FastClick.js prevent clicks on shadow DOM elements on iOS
            nibbleWindow.setAttribute('class', 'needsclick')
            this.nibbleWindowRoot = nibbleWindow
            document.body.appendChild(nibbleWindow)
            if (showWindow || (this.autoActivate && !this.isSecondaryWidget)) {
              setTimeout(() => {
                this.mainButtonClick()
              }, 50)
              this.debug('Set Configuration')
            }
          })
      } else {
        if (showWindow || (this.autoActivate && !this.isSecondaryWidget)) {
          setTimeout(() => {
            this.mainButtonClick()
          }, 50)
        }
        this.debug('Set Configuration')
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      }
    },
    loadAdapter () {
      this.features.forEach((feature) => {
        if (feature.startsWith('adapter:')) {
          this.adapter = this.findAdapter(feature.substring('adapter:'.length))
          this.adapter.activate()
        }
      })
    },
    findAdapter (name) {
      if (name === 'multi-currency-app') {
        return new MultiCurrencyAppAdapter()
      } else if (name === 'recharge-subscriptions') {
        return new ReChargeSubscriptionsAdapter()
      } else if (name === 'global-e') {
        return new GlobalEAdapter()
      }
      return null
    },
    mainButtonClick () {
      this.validate()
      this.buttonActionClick(this.buttonAction)
    },
    buttonActionClick (action) {
      this.autoActivate = false
      if (action === 'negotiate') {
        if (this.cartNegotiationUrl != null && this.negotiationType === 'cart') {
          NibbleStorage.setupAutoActivateCookie()
          window.location.href = this.cartNegotiationUrl
        } else {
          NibbleStorage.deleteAutoActivateCookie()
          this.adjustViewport()
          this.startSession()
        }
      } else if (action === 'checkout') {
        this.checkout()
      } else if (action === 'recommendations') {
        this.showRecommendations()
      } else if (action === 'add_to_basket') {
        this.addProductToBasket()
      }
    },
    showRecommendations () {
      // Pre-check: if we have an active adapter, can it verify that
      // Nibble should be enabled?
      if (this.adapter != null && !this.adapter.verifyNibbleEnabled()) {
        return
      }
      // Okay, go for it
      var message = JSON.parse(JSON.stringify(this.widgetContent.recommendation_message))
      message.inputRestriction = 'none'
      this.nibbleWindow.dispatchEvent(
        new CustomEvent('nibble-window-show', {
          detail: {
            nibbleButton: this.$refs.button.$el,
            features: this.features,
            theme: this.loadedTheme,
            sessionData: {},
            welcomeMessage: message
          }
        })
      )
      this.chatVisible = true
      this.nibbleWindowRoot.parentNode.appendChild(this.nibbleWindowRoot)
      const errorCallback = function () {}
      setTimeout(() => {
        this.$emit('nibble-get-recommendations', 0, this.recommendationsCallback, errorCallback)
      }, 2500)
    },
    recommendationsCallback (products) {
      var followupMessage = JSON.parse(JSON.stringify(this.widgetContent.recommendation_followup_message))
      followupMessage.inputRestriction = 'none'
      this.nibbleWindow.dispatchEvent(
        new CustomEvent('nibble-window-show-recommendations', {
          detail: {
            recommendationsMessage: {
              chatResponse: {
                messageLines: [
                  {
                    type: 'recommendations',
                    products: products
                  }
                ],
                inputRestriction: 'none'
              }
            },
            followupMessage: followupMessage
          }
        })
      )
    },
    startSession () {
      // Pre-check: if we have an active adapter, can it verify that
      // Nibble should be enabled?
      if (this.adapter != null && !this.adapter.verifyNibbleEnabled()) {
        return
      }
      // Okay, go for it
      if (this.dataJson) {
        try {
          var jsonData = JSON.parse(this.dataJson)
          for (var key in jsonData) {
            if (jsonData[key] instanceof Object) {
              throw Object.assign(
                new Error('Value for ' + String(key) + 'is not valid'),
                { code: 500 }
              )
            } else {
              // All ok
            }
            // TODO: we can add some sanitization here if needed
            this.parsedJson = JSON.stringify(jsonData)
          }
        } catch (error) {
          // eslint-disable-next-line
          console.error(error)
        }
      }

      this.nibbleWindow.dispatchEvent(
        new CustomEvent('nibble-window-show', {
          detail: {
            nibbleButton: this.$refs.button.$el,
            features: this.features,
            theme: this.loadedTheme,
            sessionData: {
              productId: this.productId,
              subProductId: this.productVariantId,
              tags: this.tags,
              t: this.t,
              visitNumber: this.visitNumber,
              campaignKey: this.campaignKey,
              tokenKey: this.tokenKey,
              widgetTokenKey: this.widgetTokenKey,
              optoutKey: this.optoutKey,
              pageUrl: this.getEncodedPageUrl(),
              cart: this.cartSummary,
              jsonData: this.parsedJson,
              language: this.language
            },
            welcomeMessage: null
          }
        })
      )
      this.chatVisible = true
      var token = Math.random()
      this.sessionCreationToken = token
      this.$emit('nibble-session-start', this.sessionStartSuccessCallback, this.sessionStartErrorCallback)

      // Just in case session isn't successfully created after 5 seconds, retry
      setTimeout(() => {
        // Check that the window is still visible and we're still trying to create
        // the same session
        if (this.chatVisible && this.sessionCreationToken === token) {
          this.$emit('nibble-session-start', this.sessionStartSuccessCallback, this.sessionStartErrorCallback)
        }
      }, 5000)

      // Move the Nibble window root to the bottom of the document body (see: https://stackoverflow.com/a/3415533)
      // Since the window element is already in the DOM, it is moved to the end rather than appending a copy
      this.nibbleWindowRoot.parentNode.appendChild(this.nibbleWindowRoot)
    },
    sessionStartSuccessCallback (responseData) {
      // The callback has succeeded - let's just double check that we still
      // actually want to start a session at this point
      // (Maybe the window was closed or we already retried successfuly)
      if (this.chatVisible && this.sessionCreationToken != null) {
        this.sessionCreationToken = null
        this.nibbleWindow.dispatchEvent(new CustomEvent('nibble-session-created', { detail: responseData }))
      }
    },
    sessionStartErrorCallback (errorData) {
      if (this.chatVisible && this.sessionCreationToken != null) {
        this.sessionCreationToken = null
        this.nibbleWindow.dispatchEvent(new CustomEvent('nibble-session-error', { detail: errorData }))
      }
    },
    endSession (event) {
      event.preventDefault()
      this.revertViewport()
      const session = event.detail[0]
      this.$emit('nibble-session-end', session.nibbleId, session.status, (options) => {
        var link = null
        if ('link' in options) {
          link = options.link
          options = {
            close: true
          }
        }
        this.nibbleWindow.dispatchEvent(new CustomEvent('nibble-session-ended', { detail: options }))
        if (link != null) {
          window.location.href = link
        }
      }, session.payload)
    },
    addEventProductToBasket (event) {
      event.preventDefault()
      const product = event.detail[0]
      this.addProductToBasket(product)
    },
    addProductToBasket (product) {
      if (product == null) {
        product = {
          productId: this.productId,
          subProductId: this.productVariantId,
          variantId: this.productVariantId
        }
      }
      this.$emit('nibble-add-product-to-basket', product, () => {
        this.nibbleWindow.dispatchEvent(new CustomEvent('nibble-session-ended', { detail: { close: true } }))
      })
    },
    checkout () {
      this.buttonAction = 'applying_discount'
      this.$emit('nibble-checkout')
    },
    chatDismissed () {
      this.chatVisible = false
      this.sessionCreationToken = null
      this.revertViewport()
    },
    adjustViewport () {
      var metaTag = document.querySelector('meta[name=viewport]')
      if (metaTag == null) {
        metaTag = document.createElement('meta')
        metaTag.name = 'viewport'
        metaTag.content = 'viewport-fit=cover'
        var container = document.querySelector('head')
        if (container == null) {
          container = document.body
        }
        container.appendChild(metaTag)
        /** I'm using
         * False: We created the meta tag
         * Null: No previous content to be restored
         * ...: previous content to be restored
         */
        this.previousViewportSetting = false
      } else {
        const content = metaTag.getAttribute('content')
        var props = metaParser.parseMetaViewPortContent(content)
        props = [props.invalidValues, props.unknownProperties, props.validProperties].reduce((acc, el) => {
          for (const key in el) {
            acc[key] = [...acc[key] || [], el[key]]
          }
          return acc
        }, {})
        if (props['viewport-fit'] == null || props['viewport-fit'] !== 'cover') {
          this.previousViewportSetting = content
          props['viewport-fit'] = 'cover'
          metaTag.setAttribute('content', Object.keys(props).map((prop) => `${prop}=${props[prop]}`).join(','))
        }
      }
    },
    revertViewport () {
      var metaTag = document.querySelector('meta[name=viewport]')
      if (metaTag != null) {
        // Note that I'm not using strict operator  != ( not !==  ), this means !== null | undefined | false | 0
        if (this.previousViewportSetting != null) {
          metaTag.setAttribute('content', this.previousViewportSetting)
        } else {
          // This IS a strict operator
          if (this.previousViewportSetting === false) {
            metaTag.remove()
          }
        }
      }
      this.previousViewportSetting = null
    },
    getEncodedPageUrl () {
      return btoa(window.location.href) // base64 encode page url to work around CORS/API issues
    }
  }
}
</script>

<style>
:host(nibble-button) {
  /* Don't allow the host page to accidentally set display:none */
  display: inline !important;
}
.xnib-wrapper {
  /* Allows for multiple root nodes in the Vue template */
  display: contents;
  text-align: left;
  white-space: normal;
}
.xnib-container {
  display: none;
  /* Customizable styling */
  max-width: var(--xnib-container-max-width);
  min-width: var(--xnib-container-min-width);
  margin: var(--xnib-container-margin);
  padding: var(--xnib-container-padding);
  float: var(--xnib-container-float);
}
.xnib-container-shopify-demo {
  float: right;
  margin-top: 10px;
}
  .xnib-container-shopify-demo ::after {
    clear: both;
  }
.xnib-container.show {
  display: block;
}
.xnib-container, .xnib-container ::before, .xnib-container ::after {
  box-sizing: border-box;
}
.xnib-container ::after {
  clear: both;
}
</style>
